import React from 'react';
import Editor from './components/Editor';
import BlockSpinner from './components/BlockSpinner';
import useAxiosFetch from './hooks/useAxiosFetch';

export default function App() {
  const { data, loading } = useAxiosFetch('https://api.fyyn.io/editVideo/?videoId=1234567890');
  let comp;

  if (loading) {
    comp = (
      <div className="container flex items-center justify-center relative" style={{ minHeight: 200 }}>
        <BlockSpinner />
      </div>
    )
  } else {
    comp = (<Editor videoUrl={data.videoUrl} data={data} />)
  }
  return (<>{comp}</>);
}
