// /* eslint-disable func-names */
import { useState, useRef, useEffect } from 'react'
import axios from 'axios';

import IconVolumeUp from './icons/IconVolumeUp';
import IconVolumeMute from './icons/IconVolumeMute';
import IconPlay from './icons/IconPlay';
import IconPause from './icons/IconPause';
import IconReset from './icons/IconReset';
import InlineSpinner from './InlineSpinner';


export default function Editor({ videoUrl, data }) {

  const [timings, setTimings] = useState([])

  // Boolean state to handle video mute
	const [isMuted, setIsMuted] = useState(true)

	// Boolean state to handle whether video is playing or not
	const [playing, setPlaying] = useState(false)

	// Float integer state to help with trimming duration logic
	const [difference, setDifference] = useState(0.2)

	// Boolean state to handle deleting grabber functionality
	const [deletingGrabber, setDeletingGrabber] = useState(false)

	// State for error handling
	// const [currentWarning, setCurrentWarning] = useState(null)

	// Integer state to blue progress bar as video plays
	const [seekerBar, setSeekerBar] = useState(0)

	// Ref handling metadata needed for trim markers
	const currentlyGrabbedRef = useRef({ 'index': 0, 'type': 'none' })

	// Ref handling the initial video element for trimming
	const playVideoRef = useRef()

	//Ref handling the progress bar element
	const progressBarRef = useRef()

	//Ref handling the element of the current play time
	const playBackBarRef = useRef()

  const [isSaveClick, setIsSaveClick] = useState(false);
  const [isGrabOccur, setIsGrabOccur] = useState(true);
  const [isUploading, setIsUploading] = useState(false);

	const saveUrl = data.saveUrl;
	const nextUrl = data.nextUrl;


	// Lifecycle handling the logic needed for the progress bar - displays the blue bar that grows as the video plays
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (playVideoRef.current.onloadedmetadata) {
			const currentIndex = currentlyGrabbedRef.current.index
      // console.log('timings...start', timings)

			if (timings.length > 0) {
				const seek = (playVideoRef.current.currentTime - timings[0].start) / playVideoRef.current.duration * 100
				setSeekerBar(seek)
				progressBarRef.current.style.width = `${seekerBar}%`

				if ((playVideoRef.current.currentTime >= timings[0].end)) {
					playVideoRef.current.pause()
					setPlaying(false)
					currentlyGrabbedRef.current = ({ 'index': currentIndex + 1, 'type': 'start' })
					progressBarRef.current.style.width = '0%'
					progressBarRef.current.style.left = `${timings[0].start / playVideoRef.current.duration * 100}%`
					playVideoRef.current.currentTime = timings[0].start
				}
			}
		}

		window.addEventListener('keyup', (event) => {
			if(event.key === ' ') {
				playPause()
			}
		})

		// Handles the start and end metadata for the timings state
		const time = timings

		playVideoRef.current.onloadedmetadata = () => {
      const video = playVideoRef.current;
      if (!video) return;
      console.log(`The video is ${video.duration} seconds long.`);

			if (time.length === 0) {
				time.push({'start': 0, 'end': video.duration})
				setTimings(time)
				addActiveSegments()
			}
			else {
				addActiveSegments()
			}
		}
	})

	// Lifecycle that handles removing event listener from the mouse event on trimmer - Desktop browser
	useEffect(() => {
		return window.removeEventListener('mouseup', removeMouseMoveEventListener)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Lifecycle that handles removing event listener from the touch/pointer event on trimmer - mobile browser
	useEffect(() => {
		return window.removeEventListener('pointerup', removePointerMoveEventListener)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Function handling the trimmer movement logic
	const handleMouseMoveWhenGrabbed = (event) => {
		playVideoRef.current.pause()
		addActiveSegments()
    setIsGrabOccur(true);

		if (isSaveClick) {
			setIsSaveClick(false);
		}

		let playbackRect = playBackBarRef.current.getBoundingClientRect()
		let seekRatio = (event.clientX - playbackRect.left) / playbackRect.width
		const index = currentlyGrabbedRef.current.index
		const type = currentlyGrabbedRef.current.type
		let time = timings
		let seek = playVideoRef.current.duration * seekRatio
		if ((type === 'start') && (seek > ((index !== 0) ? (time[index - 1].end + difference + 0.2) : 0)) && seek < time[index].end - difference) {
			progressBarRef.current.style.left = `${seekRatio * 100}%`
			playVideoRef.current.currentTime = seek
			time[index]['start'] = seek
			setPlaying(false)
			setTimings(time)
		}
		else if((type === 'end') && (seek > time[index].start + difference) && (seek < (index !== (timings.length - 1) ? time[index].start - difference - 0.2 : playVideoRef.current.duration))){
			progressBarRef.current.style.left = `${time[index].start / playVideoRef.current.duration * 100}%`
			playVideoRef.current.currentTime = time[index].start
			time[index]['end'] = seek
			setPlaying(false)
			setTimings(time)
		}
		progressBarRef.current.style.width = '0%'
	}

	// Function that handles removing event listener from the mouse event on trimmer - Desktop browser
	const removeMouseMoveEventListener = () => {
		window.removeEventListener('mousemove', handleMouseMoveWhenGrabbed)
	}

	// Lifecycle that handles removing event listener from the mouse event on trimmer - Mobile browser
	const removePointerMoveEventListener = () => {
		window.removeEventListener('pointermove', handleMouseMoveWhenGrabbed)
	}

	// Function handling reset logic
	const reset = () => {
		playVideoRef.current.pause()
    setIsMuted(false)
		setPlaying(false)
		currentlyGrabbedRef.current = {'index': 0, 'type': 'none'}
		setDifference(0.2)
		setDeletingGrabber(false)

		setTimings([{'start': 0, 'end': playVideoRef.current.duration}])
		playVideoRef.current.currentTime = timings[0].start
		progressBarRef.current.style.left = `${timings[0].start / playVideoRef.current.duration * 100}%`
		progressBarRef.current.style.width = '0%'
		addActiveSegments();
	}


	// Function handling play and pause logic
	const playPause = () => {
		if (playing){
			playVideoRef.current.pause()
		}
		else {
			if ((playVideoRef.current.currentTime >= timings[0].end)) {
				playVideoRef.current.pause()
				setPlaying(false)
				currentlyGrabbedRef.current = {'index': 0, 'type': 'start'}
				playVideoRef.current.currentTime = timings[0].start
				progressBarRef.current.style.left = `${timings[0].start / playVideoRef.current.duration * 100}%`
				progressBarRef.current.style.width = '0%'
			}
			playVideoRef.current.play()
		}
		setPlaying(!playing)
	}


	// Function handling updating progress logic (clicking on progress bar to jump to different time durations)
	const updateProgress = (event) => {
		let playbackRect = playBackBarRef.current.getBoundingClientRect()
		let seekTime = ((event.clientX - playbackRect.left) / playbackRect.width) * playVideoRef.current.duration
		playVideoRef.current.pause()
		// find where seekTime is in the segment
		let index = -1
		let counter = 0

		for (let times of timings) {
			if (seekTime >= times.start && seekTime <= times.end) {
				index = counter
			}
			counter += 1
		}

		if (index === -1) {
			return
		}
		setPlaying(false)
		currentlyGrabbedRef.current = {'index': index, 'type': 'start'}
		progressBarRef.current.style.width = '0%' // Since the width is set later, this is necessary to hide weird UI
		progressBarRef.current.style.left = `${timings[index].start / playVideoRef.current.duration * 100}%`
		playVideoRef.current.currentTime = seekTime
	}

	// Function handling deletion of trimmers logic
	const deleteGrabber = (index) => {
		let time = timings
		setDeletingGrabber({deletingGrabber: false, currentWarning: null, currentlyGrabbed: {'index': 0, 'type': 'start'}})
		setDeletingGrabber({deletingGrabber: false, currentWarning: null, currentlyGrabbed: {'index': 0, 'type': 'start'}})
		if(time.length === 1){
			return
		}
		time.splice(index, 1)
		progressBarRef.current.style.left = `${time[0].start / playVideoRef.current.duration * 100}%`
		playVideoRef.current.currentTime = time[0].start
		progressBarRef.current.style.width = '0%'
		addActiveSegments()
	}

	// Function handling logic of time segments throughout videos duration
	const addActiveSegments = () => {
		let colors = ''
		let counter = 0
		colors += `, rgb(240, 240, 240) 0%, rgb(240, 240, 240) ${timings[0].start / playVideoRef.current.duration * 100}%`
		for (let times of timings) {
			if (counter > 0) {
				colors += `, rgb(240, 240, 240) ${timings[counter].end / playVideoRef.current.duration * 100}%, rgb(240, 240, 240) ${times.start / playVideoRef.current.duration * 100}%`
			}
			colors += `, #ccc ${times.start / playVideoRef.current.duration * 100}%, #ccc ${times.end / playVideoRef.current.duration * 100}%`
			counter += 1
		}
		colors += `, rgb(240, 240, 240) ${timings[counter - 1].end / playVideoRef.current.duration * 100}%, rgb(240, 240, 240) 100%`
		playBackBarRef.current.style.background = `linear-gradient(to right${colors})`
	}

  function timeFormat(duration) {

    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }

	const saveVideo = async () => {
		let metadata = {
			'trim_times': timings,
      'mute': isMuted,
		}

		const trimStart = metadata.trim_times[0].start
		const trimEnd = metadata.trim_times[0].end

    const now = new Date().getTime();

    const dataCompose = {
      videoId: data.videoId,
      trimStart: Number.parseFloat(trimStart).toFixed(2),
      trimEnd: Number.parseFloat(trimEnd).toFixed(2),
    }

    setIsUploading(true);

    axios.post(`${saveUrl}?${now}`  , dataCompose)
    .then((res) => {
      if (res.status === 200) {
			 console.log('Trim values saved successfully')
       setIsSaveClick(true);
       setIsGrabOccur(false);
       setIsUploading(false);
      }
    })
    .catch(function (err) {
      console.log(err);
    })
	}

	return (
		<div className="container">
      <div className="fn__container">
        <video className="fn__video"
          autoPlay
          muted={isMuted}
          autoload='metadata'
          ref={playVideoRef}
          onLoadedData={() => playPause()}
          onClick={() => playPause()}
          onTimeUpdate={() => {
            setSeekerBar(progressBarRef.current.style.width)
          }}
        >
          <source src={videoUrl} type='video/mp4' />
        </video>

        <div className="player-cb flex">
          <button
            title="Play/Pause"
            className="player-cb__button button-play"
            onClick={playPause}
          >
            {playing ? <IconPause size="30px" /> : <IconPlay size="30px" /> }
          </button>

          <button
            title="Reset Video"
            className="player-cb__button button-reset"
            onClick={reset}
          >
            <IconReset size="25px" />
          </button>
          <button
            title="Mute/Unmute Video"
            className="player-cb__button button-mute"
            onClick={() => setIsMuted(!isMuted)}
            >
              {isMuted ? <IconVolumeMute size="25px" /> : <IconVolumeUp size="25px" /> }
            </button>
        </div>
      </div>

      <div className="playback-container">
        <div className="playback">
          {/* If there is an instance of the playVideoRef, render the trimmer markers */}
          {playVideoRef.current ?
            Array.from(timings).map((timing, index) => (
              <div key={index}>
                <div key={'grabber_' + index}>
                  {/* Markup and logic for the start trim marker */}
                  <div id="grabberStart" className="grabber start flex items-center justify-center"
                    style={{ left: `${timings[0].start / playVideoRef.current.duration * 100}%`}}
                    // Events for desktop - Start marker
                    onMouseDown={(event) => {
                      if(deletingGrabber){
                        deleteGrabber(index)
                      }
                      else {
                        currentlyGrabbedRef.current = {'index': index, 'type': 'start'}
                        window.addEventListener('mousemove', handleMouseMoveWhenGrabbed)
                        window.addEventListener('mouseup', removeMouseMoveEventListener)
                      }
                    }}
                    //Events for mobile - Start marker
                    onPointerDown={() => {
                      if(deletingGrabber){
                        deleteGrabber(index)
                      }
                      else{
                        currentlyGrabbedRef.current = {'index': index, 'type': 'start'}
                        window.addEventListener('pointermove', handleMouseMoveWhenGrabbed)
                        window.addEventListener('pointerup', removePointerMoveEventListener)
                      }
                    }}
                  >
                    <svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0' y='0' width='10' height='14' viewBox='0 0 10 14' xmlSpace='preserve'>
                      <path className='st0' d='M1 14L1 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C2 13.6 1.6 14 1 14zM5 14L5 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C6 13.6 5.6 14 5 14zM9 14L9 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C10 13.6 9.6 14 9 14z'/>
                    </svg>
                  </div>

                  {/* Markup and logic for the end trim marker */}
                  <div id="grabberEnd" className="grabber end flex items-center justify-center"
                    style={{ left: `${timings[0].end / playVideoRef.current.duration * 100}%`}}
                    //Events for desktop - End marker
                    onMouseDown={(event) => {
                      if(deletingGrabber){
                        deleteGrabber(index)
                      }
                      else{
                        currentlyGrabbedRef.current = {'index': index, 'type': 'end'}
                        window.addEventListener('mousemove', handleMouseMoveWhenGrabbed)
                        window.addEventListener('mouseup', removeMouseMoveEventListener)
                      }
                    }}
                    //Events for mobile - End marker
                    onPointerDown={() => {
                      if(deletingGrabber){
                        deleteGrabber(index)
                      }
                      else{
                        currentlyGrabbedRef.current = {'index': index, 'type': 'end'}
                        window.addEventListener('pointermove', handleMouseMoveWhenGrabbed)
                        window.addEventListener('pointerup', removePointerMoveEventListener)
                      }
                    }}
                  >
                    <svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0' y='0' width='10' height='14' viewBox='0 0 10 14' xmlSpace='preserve'>
                      <path className='st0' d='M1 14L1 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C2 13.6 1.6 14 1 14zM5 14L5 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C6 13.6 5.6 14 5 14zM9 14L9 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C10 13.6 9.6 14 9 14z'/>
                    </svg>
                  </div>
                </div>
              </div>
            ))
            : []}
          <div className='seekable' ref={playBackBarRef} onClick={updateProgress}></div>
          <div className='progress' ref={progressBarRef}></div>
        </div>

        {playVideoRef.current && (
          <div className="pb-times flex items-center justify-between">
            <div>{timeFormat(timings[0].start)}</div>
            <div>{timeFormat(timings[0].end)}</div>
          </div>
        )}
      </div>

			<div className="controls flex items-center justify-center">
				<div>
					<button
            title="Save changes"
            className="button-trim-save"
            onClick={saveVideo}
            disabled={isSaveClick && !isGrabOccur}
          >
            {isUploading && (<InlineSpinner />)}
            {!isUploading && (<span>Save</span>)}
          </button>
				</div>
        <div>
          <button
            title="Next step"
            className="button-next"
            onClick={() => window.location.href = nextUrl}
            disabled={!isSaveClick && isGrabOccur}
          >
            Next
          </button>
				</div>
			</div>
		</div>
	)
}
